import React from 'react'
import photo from '../images/what.webp';
import { Link } from 'react-router-dom';

function WhyUs() {

    return (
        <div id='About'>
            <div className='flex justify-center md:flex-row flex-col h-full px-3 mx-auto items-center pt-12 md:pt-[8rem] md:w-[80%] md:gap-24 gap-10 w-full'>

                <div className='md:w-1/2 rounded-xl relative h-full w-full'>

                    <img
                        src={photo}

                        alt="Background Photo"
                        className="rounded-xl h-full"
                    />
                </div>

                <div className='flex justify-end flex-col items-start h-full  gap-8 md:w-1/2 w-full'>
                    <h1 className='md:text-[45px] text-[30px] font-[900] md:leading-[50px]  leading-[40px]  text-secondary capitalize'>
                        What is ADHD?

                    </h1>
                    <div className='flex md:text-[18px] text-[14px] font-[400] leading-[30px] justify-end flex-col items-start h-full gap-10 W-full'>

                        <p className=' text-black capitalize'>
                            ADHD, or Attention-Deficit/Hyperactivity Disorder, is a neurodevelopmental disorder that affects both children and adults. It is characterized by persistent patterns of inattention, hyperactivity, and impulsivity that can interfere with daily functioning and development.

                        </p>
                        <p className=' text-black capitalize'>
                            Those with ADHD may experience challenges in focusing on tasks, managing time effectively, and maintaining relationships, which can lead to feelings of frustration and low self-esteem. Early diagnosis and intervention are crucial, as they can significantly improve outcomes and help individuals develop coping strategies to thrive in various aspects of life.

                        </p>
                    </div>




                </div>






            </div>
            <div className='flex md:flex-row flex-col jaustify-center items-center mx-auto py-12 md:py-[6rem] md:gap-10 gap-5 md:w-[90%] px-3 w-full'>

                <div className='flex flex-col w-full md:w-1/3 justify-center items-center gap-4 px-5 py-6 rounded-xl bg-gray-100 '>
                    <div className='p-5 text-white bg-primary rounded-full'>
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-scan-search"><path d="M3 7V5a2 2 0 0 1 2-2h2" /><path d="M17 3h2a2 2 0 0 1 2 2v2" /><path d="M21 17v2a2 2 0 0 1-2 2h-2" /><path d="M7 21H5a2 2 0 0 1-2-2v-2" /><circle cx={12} cy={12} r={3} /><path d="m16 16-1.9-1.9" /></svg>
                    </div>
                    <h1 className='md:text-[25px] text-[18px] font-[800] md:leading-[30px]  leading-[20px]  text-secondary capitalize'>
                        Inattentive Type
                    </h1>
                    <p className='md:text-[18px] text-[14px] font-[400] leading-[30px]  text-secondary text-center capitalize'>
                        This type is marked by difficulty focusing, forgetfulness, and disorganization, often leading to misplaced items and unfinished tasks.
                    </p>
                </div>

                <div className='flex flex-col w-full md:w-1/3 justify-center items-center gap-4 px-5 py-6 rounded-xl bg-gray-100 '>
                    <div className='p-5 text-white bg-primary rounded-full'>
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-activity"><path d="M22 12h-2.48a2 2 0 0 0-1.93 1.46l-2.35 8.36a.25.25 0 0 1-.48 0L9.24 2.18a.25.25 0 0 0-.48 0l-2.35 8.36A2 2 0 0 1 4.49 12H2" /></svg>
                    </div>
                    <h1 className='md:text-[25px] text-[18px] font-[800] md:leading-[30px]  leading-[20px]  text-secondary capitalize'>
                        Hyperactive-Impulsive Type
                    </h1>
                    <p className='md:text-[18px] text-[14px] font-[400] leading-[30px]  text-secondary text-center capitalize'>
                        Characterized by excessive fidgeting and difficulty staying seated, individuals may interrupt others and make impulsive decisions.
                    </p>
                </div>

                <div className='flex flex-col w-full md:w-1/3 justify-center items-center gap-4 px-5 py-6 rounded-xl bg-gray-100 '>
                    <div className='p-5 text-white bg-primary rounded-full'>
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-brain"><path d="M12 5a3 3 0 1 0-5.997.125 4 4 0 0 0-2.526 5.77 4 4 0 0 0 .556 6.588A4 4 0 1 0 12 18Z" /><path d="M12 5a3 3 0 1 1 5.997.125 4 4 0 0 1 2.526 5.77 4 4 0 0 1-.556 6.588A4 4 0 1 1 12 18Z" /><path d="M15 13a4.5 4.5 0 0 1-3-4 4.5 4.5 0 0 1-3 4" /><path d="M17.599 6.5a3 3 0 0 0 .399-1.375" /><path d="M6.003 5.125A3 3 0 0 0 6.401 6.5" /><path d="M3.477 10.896a4 4 0 0 1 .585-.396" /><path d="M19.938 10.5a4 4 0 0 1 .585.396" /><path d="M6 18a4 4 0 0 1-1.967-.516" /><path d="M19.967 17.484A4 4 0 0 1 18 18" /></svg>
                    </div>
                    <h1 className='md:text-[25px] text-[18px] font-[800] md:leading-[30px]  leading-[20px]  text-secondary capitalize'>
                        Combined Type
                    </h1>
                    <p className='md:text-[18px] text-[14px] font-[400] leading-[30px]  text-secondary text-center capitalize'>
                        This type includes symptoms from both inattentive and hyperactive-impulsive types, resulting in challenges with concentration and impulsivity.
                    </p>
                </div>

            </div>
        </div>

    )
}

export default WhyUs