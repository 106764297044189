import React, { useState } from 'react'
import logo from '../images/logo.png';
import { Link, useLocation } from 'react-router-dom';
function Navbar() {

    const [dropdown, setDropDown] = useState(false)
    const location = useLocation();


    return (
        <div className='flex   justify-between absolute top-0 z-50 w-screen  items-center md:px-[7rem] px-6 md:h-[5.8rem] h-[4rem]'>
            <div className='md:flex hidden justify-start items-center w-1/3 '>
                <Link to='/'><img src={logo} alt='logo' objectFit="cover" className='h-[3.5rem]' /></Link>
            </div>

            <ul className='md:flex hidden justify-center gap-12   cursor-pointer items-center text-neutral-200   font-[600] text-[18px] capitalize'>
                <li className={`hover:text-primary  ${location.pathname == '/' ? ' text-primary border-primary' : ''}`}><Link to='/'>Home</Link></li>
                <li className={`hover:text-primary `}><a href='#About'>About</a></li>
                <li className={`hover:text-primary `}><a href='#FAQ'>FOQ</a></li>
                <li className={`hover:text-primary ${location.pathname == '/Blog' ? 'text-primary border-primary' : ''}`}><Link to='/Blog'>Blog</Link></li>
            </ul>
            
            <div className="text-primary md:hidden  w-full flex justify-between items-center">

                <Link to='/'><img src={logo} alt='logo' objectFit="cover" className='w-44' /></Link>

                {dropdown && (
                    <div
                        className={`absolute top-[4rem] right-0 flex-col flex w-screen justify-start items-center z-50 bg-white duration-300 ease-in-out transform ${dropdown ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'
                            }`}
                    >
                        <Link
                            onClick={() => setDropDown(!dropdown)}
                            to="/"
                            className="text-primary scroll-link cursor-pointer font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                        >
                            Home
                        </Link>

                        <Link
                            onClick={() => setDropDown(!dropdown)}
                            to="/Blog"
                            className="text-primary scroll-link cursor-pointer font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                        >
                            Blog's
                        </Link>
                    </div>
                )}

                <button onClick={() => setDropDown(!dropdown)} className="focus:outline-none z-50">
                    {dropdown ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="size-6 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    )}
                </button>
            </div>

        </div>

    )
}

export default Navbar