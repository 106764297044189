
import React, { useState } from 'react';
// import photo from '../images/question.png';
function Faq() {


    const [openAccordion, setOpenAccordion] = useState(null);

    const toggleAccordion = (index) => {
        setOpenAccordion(openAccordion === index ? null : index);
    };
    const faqs = [
        {
            question: "What is ADHD?",
            answer: "ADHD, or Attention-Deficit/Hyperactivity Disorder, is a neurodevelopmental disorder characterized by symptoms of inattention, hyperactivity, and impulsivity that can affect daily functioning.",
        },
        {
            question: "What are the common symptoms of ADHD?",
            answer: "Common symptoms include difficulty focusing, forgetfulness, impulsive decision-making, restlessness, and trouble organizing tasks. Symptoms can vary between individuals.",
        },
        {
            question: "How is ADHD diagnosed?",
            answer: "ADHD is diagnosed through a comprehensive evaluation by a healthcare professional, which may include interviews, behavior assessments, and feedback from parents or teachers.",
        },
        {
            question: "What treatments are available for ADHD?",
            answer: "Treatment options for ADHD may include behavioral therapy, medication, coaching, and educational support tailored to the individual's needs.",
        },
    ];




    return (
        <div id='FAQ' className='w-full flex flex-col gap-14 justify-center items-center  py-14 md:py-[7rem]  md:px-56 px-3'>
            <div className='w-full flex justify-center text-center gap-5 md:px-56 items-center flex-col'>
                {/* <img
                    src={photo}
                    alt="Background Photo"
                    className="rounded-xl w-[15rem]"
                /> */}

                <h1 className='md:text-[55px] text-[30px] font-[900]  leading-[50px] md:leading-[60px] text-neutral-700 capitalize'>
                    Discover Answers to Your Most Pressing Questions!

                </h1>
                <p className='md:text-[20px] text-[14px] font-[400] md:leading-[40px] leading-[25px] text-neutral-700'>
                    Uncover valuable insights about ADHD, effective management strategies, and how our community can support you on your journey. Whether you're looking for resources, tips, or encouragement, we’ve got you covered!
                </p>



                <div className="md:mt-14 mt-5" >
                    {faqs.map((faq, index) => (
                        <div
                            key={index}
                            className={` border border-solid border-gray-300 p-4  rounded-xl mb-6 lg:p-4 ${openAccordion === index ? 'bg-gray-50 border-primary' : ''
                                }`}
                        >
                            <button
                                className=" inline-flex items-center justify-between md:text-[17px] text-[13px] font-[600] leading-[20px] text-neutral-700 w-full transition duration-500 "
                                onClick={() => toggleAccordion(index)}
                            >
                                <h5 className='text-start'>{faq.question}</h5>
                                <svg
                                    className={`w-6 h-6 text-gray-900 transition duration-500 ${openAccordion === index ? 'hidden' : 'block'
                                        } group-hover:text-indigo-600 origin-center`}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M6 12H18M12 18V6" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <svg
                                    className={`w-6 h-6 text-gray-900 transition duration-500 ${openAccordion === index ? 'block' : 'hidden'
                                        } group-hover:text-indigo-600`}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M6 12H18" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                            <div
                                className=" w-full overflow-hidden pr-4 transition-max-height duration-500"
                                style={{
                                    maxHeight: openAccordion === index ? '500px' : '0px',
                                }}
                            >
                                <p className="md:text-[18px] text-[15px] font-[400] text-start leading-[26px] text-neutral-700 mt-2">
                                    {faq.answer}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>


            </div>






        </div>
    )
}

export default Faq