import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter } from 'react-router-dom';

import Navbar from './layout/Navbar';
import Footer from './layout/Footer';

import Home from './pages/Home';
import Blog from './pages/Blog';
import Post from './pages/Post';

function App() {



  return (
    <BrowserRouter>

        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Post/:slug" element={<Post />} />

        </Routes>
        <Footer />

    </BrowserRouter>
  );
}

export default App;
