import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../images/logo.png'

const currentYear = new Date().getFullYear();

function Footer() {


    return (


        <footer className="bg-secondary  shadow ">
            <div className="w-full max-w-screen-xl mx-auto p-4 py-8">
                <div className="flex items-center flex-col md:flex-row gap-6 md:justify-between">
                    <div className='flex  md:justify-start justify-center items-center md:w-1/3 '>
                        <Link to='/'><img src={logo} alt='logo' objectFit="cover" className='md:h-[3.5rem] h-12' /></Link>
                    </div>
                    <ul className="flex flex-wrap items-center mb-6 text-neutral-200   font-[600] md:text-[16px] text-[14px] capitalize sm:mb-0 ">
                        <li>
                            <Link to='/' className="hover:underline hover:text-primary me-4 md:me-6">Home</Link >
                        </li>
                        <li>
                            <a href="#About" className="hover:underline hover:text-primary me-4 md:me-6">About</a >
                        </li>
                        <li>
                            <a href="#FAQ" className="hover:underline hover:text-primary me-4 md:me-6">FAQ</a >
                        </li>
                        <li>
                            <Link to='/Blog' className="hover:underline hover:text-primary">Blog</Link >
                        </li>
                    </ul>
                </div>
                <hr className="my-6 border-neutral-700 sm:mx-auto  lg:my-8" />
                <span className="block text-sm text-neutral-500 text-center ">© {currentYear} <Link to='' href="https://flowbite.com/" className="hover:underline">ADHD Hacks Hub</Link >. All Rights Reserved.</span>
            </div>
        </footer>




    )
}

export default Footer