import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Hero({ postData }) {
    const { t } = useTranslation()

    return (
        <div className='w-full h-fit relative flex justify-center items-center' >
            <div className="absolute inset-0 bg-secondary z-10 opacity-40"></div>
            <div
                className="absolute inset-0 bg-cover  bg-center"
                style={{ backgroundImage: `url(${postData?.image})` }}
            ></div>


            <div className='flex z-20 flex-col md:py-40 py-24 justify-center md:w-[80%] text-center w-full mx-auto px-3  items-center md:gap-6 gap-4 '>

                <h1 className='md:text-[48px] text-[30px]  font-[800] leading-[40px] capitalize md:leading-[70px] text-gray-100'>
                    {postData?.title}
                </h1>
                <p className='md:text-[17px] text-[14px] font-[400] leading-[25px] md:leading-[40px] text-gray-100'>
                    {postData?.description}
                </p>
                
            </div>
        </div>
    );
}

export default Hero;
