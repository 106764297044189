import React, { useEffect } from 'react'
import Faq from "../components/Faq";
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Hero from "../components/Hero";
import WhyUs from '../components/WhyUs';
import Articles from '../components/Articles';
export default function Home() {


    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This makes the scroll smooth
        });
    }, [pathname]);


    return (
        <div className="h-full w-full">
            <Helmet>
                <title>ADHD Hacks Hub</title>
                <meta name="description" content="Empowering Lives Through ADHD Knowledge" />
            </Helmet>
            <Hero />
            <WhyUs />
            <Articles />
            <Faq />

        </div>
    );
}
