
import React from 'react'
import { Link } from 'react-router-dom';

function BlogCard({ post }) {
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return new Date(dateString).toLocaleString('en-US', options); // Customize locale as needed
    };
    return (
        <Link
            to={`/Post/${post.slug}`}
            key={post.slug}
            className="relative hover:border-b-[4px] border-primary transition-all duration-500 flex flex-col  bg-white shadow-sm rounded-lg  w-full h-full"
        >
            <div className="relative w-full h-56  overflow-hidden rounded-md">
                <img
                    src={post.image}
                    alt={post.title} // Updated alt attribute for better accessibility
                    className="w-full h-full object-cover" // Added class for proper image scaling
                />

                <div className='bg-primary px-3 py-1 text-neutral-100 text-[12px] font-[500] absolute bottom-0 rounded-b-md rounded-r-md z-50'>
                    {formatDate(post.created_at)}
                </div>
            </div>
            <div className="p-4">
                <h6 className="mb-2 line-clamp-2 text-slate-800 text-xl font-semibold">
                    {post.title}
                </h6>
                <p className="text-slate-600 line-clamp leading-normal font-light">
                    {post.description}
                </p>
            </div>
        </Link>


    )
}

export default BlogCard