import React from 'react';
import photo from '../images/hero.jpg';
import { Link } from 'react-router-dom';

function Hero() {

    return (
        <div className='w-full h-fit  relative flex justify-center   py-12 items-center' >
            <div className="absolute inset-0 bg-secondary/50 z-10 "></div>
            <div
                className="absolute inset-0 bg-cover  bg-center"
                style={{ backgroundImage: `url(${photo})` }}
            ></div>




            {/* Content Section */}
            <div className='flex z-20 flex-col justify-center md:w-[45%] gap-5  py-24 text-center  w-full  px-3  mt-6 md:mt-10 items-center  '>
                <p className='text-yellow-400 font-[600] md:text-[18px] font-per text-[14px]'>
                    Empowering Lives Through Knowledge
                </p>

                <h1 className='md:text-[58px] text-[30px] font-[800] leading-[40px] md:leading-[60px] text-gray-100'>
                    Unlock Your Potential with ADHD Hacks
                </h1>

                <p className='md:text-[23px] text-[14px] font-[400] leading-[25px] md:leading-[40px] text-gray-100'>
                    Your go-to source for ADHD insights, practical tips, and inspiring stories to thrive in daily life.
                </p>

                <div className='flex justify-center items-center gap-3 w-full'>
                    <Link to='/Blog' className='flex justify-center items-center gap-2 capitalize  md:px-7 px-2.5 py-3 rounded-full bg-primary text-neutral-100  font-[700]   md:text-[18px] text-[14px] w-fit '>
                        <span>Explore Our Blog</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                    </Link>

                </div>
            </div>
        </div>
    );
}

export default Hero;
