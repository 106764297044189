import React, { useState, useEffect } from 'react';
import BlogCard from './Blog/BlogCard';
import { Link } from 'react-router-dom';
import Loading from './Loading';
import axios from 'axios';
import { toast } from 'react-toastify';

function Articles() {

    const [blog, setBlog] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getBlog();
    }, []);

    const getBlog = async (page = 1) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://api.dashforme.xyz/public/api/blogs/searchBlogs/adhdhackshub?page=${page}`);
            const responseData = response.data;

            if (responseData) {

                setBlog(responseData.data);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred while fetching the blogs.');
        } finally {
            setLoading(false);
        }
    };
    return (
        <div id='FAQ' className='w-full flex flex-col gap-14 justify-center items-center  py-14 md:py-[7rem] bg-gray-100 xl:px-44 lg:px-32 ms:px-24 sm:px-12 px-3'>
            <div className='flex w-full justify-between md:flex-row flex-col items-center gap-5 '>

                <h1 className='md:text-[45px] text-[30px] font-[900] text-center leading-[50px] md:leading-[60px] text-neutral-800 capitalize'>
                    News and Articles

                </h1>
                <Link to='/Blog' className='flex justify-center items-center gap-2 capitalize  md:px-7 px-2.5 py-3 rounded-full bg-primary text-neutral-100  font-[700]   md:text-[18px] text-[14px] w-fit '>
                    <span>Explore Our Blog</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                </Link>

            </div>

            <div className='grid xl:grid-cols-4 place-items-center md:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:gap-10 gap-5'>
                {loading ? (
                    <div className='md:col-span-4'>
                        <Loading />
                    </div>
                ) : (
                    blog.slice(0, 4).map((post) => <BlogCard key={post.id} post={post} />)
                )}
            </div>

        </div>
    )
}

export default Articles