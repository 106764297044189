import React from 'react';
import { Link } from 'react-router-dom';
import photo from '../../images/hero.jpg';

function Hero() {

    return (
        <div className='w-full h-fit   relative flex justify-center items-center' >
            <div className="absolute inset-0 bg-secondary/50 z-10 "></div>
            <div
                className="absolute inset-0 bg-cover  bg-center"
                style={{ backgroundImage: `url(${photo})` }}
            ></div>


            {/* Content Section */}
            <div className='flex z-20 flex-col md:py-32 py-24 justify-center md:w-[50%] text-center w-full 
             mx-auto px-3 items-center gap-5 '>

                <p className='text-yellow-400 font-per font-semibold md:text-2xl text-lg'>
                    Navigating Life with ADHD
                </p>

                <h1 className='md:text-[58px] text-[34px] font-bold leading-[1.2] text-gray-100'>
                    Discover Effective Strategies and Support
                </h1>

                <p className='md:text-[23px] text-[16px] font-normal leading-normal text-gray-300  mx-auto'>
                    Explore valuable insights, proven techniques, and uplifting stories designed to help you manage ADHD and reach your goals with confidence.
                </p>

            </div>
        </div>
    );
}

export default Hero;
